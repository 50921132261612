<template>
  <v-snackbar
    v-if="toastOptions"
    v-model="showToast"
    v-bind="toastOptions"
    data-test="global-snackbar"
  >
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-html="toastOptions.message" />

    <template v-slot:action="{ attrs }">
      <v-btn v-if="toastOptions.closable" icon v-bind="attrs" @click="showToast = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'global-snackbar',
  data() {
    return {
      showToast: false,
    }
  },
  computed: {
    ...mapState(['toastOptions']),
  },
  watch: {
    async toastOptions() {
      if (!this.showToast) {
        this.showToast = true
        return
      }

      this.showToast = false
      setTimeout(() => {
        this.showToast = true
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  margin-right: 0.5rem;
}
</style>
